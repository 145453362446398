import { Injectable } from '@angular/core';

import { scope, menuacl } from '@azavista/acl';
import {
    ActivityScopes, CampaignScopes, ContactScopes, DependencyScopes, EmailCampaignTypeScopes,
    EventFieldScopes, EventScopes, GlobalEventEmailTemplateScopes, IntegrationScopes, OrganizationScopes, PageScopes, ParticipantScopes,
    ProcessScopes, ProfilePageScopes, ReportScopes, RoomManagementScopes, SeriesScopes, StageScopes,
    ThemeScopes, VirtualMeetingScopes, WebhookScopes, ProjectScopes, TaskScopes,
    EventEventAppScopes, GlobalProcessScopes
} from '@azavista/acl/lib/acl';

@Injectable({
    providedIn: 'root'
})
export class RequiredScopesService {
    getScopeA(): string {
        return scope.Global.Admin;
    }

    // GlobalEventEmailTemplateScopes
    getRequiredCreateGlobalEventEmailTemplateScopes(): string[] {
        return GlobalEventEmailTemplateScopes.canCreate;
    }

    getRequiredReadGlobalEventEmailTemplateScopes(): string[] {
        return GlobalEventEmailTemplateScopes.canRead;
    }

    // Reports
    getRequiredViewReportDetailsScopes(): string[] {
        return ReportScopes.canRead;
    }

    getRequiredViewLocalReportDetailsScopes(): string[] {
        return ReportScopes.canReadLocal;
    }

    getRequiredCreateReportScopes(): string[] {
        return ReportScopes.canCreate;
    }

    getRequiredCreateLocalReportScopes(): string[] {
        return ReportScopes.canCreateLocal;
    }

    getRequiredUpdateReportScopes(): string[] {
        return ReportScopes.canUpdate;
    }

    getRequiredUpdateLocalReportScopes(): string[] {
        return ReportScopes.canUpdateLocal;
    }

    getRequiredDeleteReportScopes(): string[] {
        return ReportScopes.canDelete;
    }

    getRequiredDeleteLocalReportScopes(): string[] {
        return ReportScopes.canDeleteLocal;
    }

    getRequiredCreateReportVersionScopes(): string[] {
        return ReportScopes.canCreate;
    }

    getRequiredCreateLocalReportVersionScopes(): string[] {
        return ReportScopes.canCreateLocal;
    }


    // Modules
    getReportsMenuScopes(): string[] {
        return menuacl.GlobalMenu.Reports.Main;
    }

    getEventReportsMenuScopes(): string[] {
        return menuacl.EventMenu.Reports.Main;
    }

    getEventsMenuScopes(): string[] {
        return menuacl.GlobalMenu.Events.Main;
    }

    getCRMMenuScopes(): string[] {
        return menuacl.GlobalMenu.CRM.Main;
    }

    getFieldsManagerMenuScopes(): string[] {
        return menuacl.GlobalMenu.FieldsManager.Main;
    }

    getGlobalProcessesIntegrationsMenuScopes(): string[] {
        return menuacl.GlobalMenu.Integrations.Integrations;
    }

    getRoomsManagementMenuScopes(): string[] {
        return menuacl.GlobalMenu.RoomsManagement.Main;
    }

    getIntegrationsGlobalMenuAllScopes(): string[] {
        return menuacl.GlobalMenu.Integrations.All;
    }

    getEventProcessCreateScope(): string {
        return scope.EventProcess.Create;
    }

    getGlobalEventProcessCreateScopes(): string[] {
        return GlobalProcessScopes.canCreate.Event;
    }

    getGlobalEventProcessReadScopes(): string[] {
        return GlobalProcessScopes.canRead.Event;
    }

    getGlobalContactProcessCreateScopes(): string[] {
        return GlobalProcessScopes.canCreate.Contact;
    }

    getGlobalContactProcessReadScopes(): string[] {
        return GlobalProcessScopes.canRead.Contact;
    }

    getGlobalOrganizationProcessCreateScopes(): string[] {
        return GlobalProcessScopes.canCreate.Organization;
    }

    getGlobalOrganizationProcessReadScopes(): string[] {
        return GlobalProcessScopes.canRead.Organization;
    }

    getEventProcessReadScope(): string {
        return scope.EventProcess.Read;
    }

    getTicketsAndProductsMenuScopes(): string[] {
        return menuacl.GlobalMenu.TicketsAndProducts.Main;
    }

    getAllEventTicketsAndProductsModuleScopes(): string[] {
        return [scope.Event.Create, scope.Event.Read, scope.Event.Update, scope.Event.Delete, scope.Event.Copy, scope.Event.ManageFinances];
    }

    // Event room settings

    getRoomManagementCanDeleteScopes(): string[] {
        return RoomManagementScopes.canDelete;
    }

    getRoomManagementCanUpdateScopes(): string[] {
        return RoomManagementScopes.canUpdate;
    }

    getRoomManagementCanCreateScopes(): string[] {
        return RoomManagementScopes.canCreate;
    }

    getRoomManagementCanReadScopes(): string[] {
        return RoomManagementScopes.canRead;
    }

    // Floor plans

    getRequiredGetFloorPlanScopes(): string[] {
        return RoomManagementScopes.canRead;
    }

    getRequiredCreateFloorPlanScopes(): string[] {
        return RoomManagementScopes.canCreate;
    }

    getRequiredUpdateFloorPlanScopes(): string[] {
        return RoomManagementScopes.canUpdate;
    }

    getRequiredDeleteFloorPlanScopes(): string[] {
        return RoomManagementScopes.canDelete;
    }

    // Amenities

    getRequiredGetAmenityScopes(): string[] {
        return RoomManagementScopes.canRead;
    }

    getRequiredCreateAmenityScopes(): string[] {
        return RoomManagementScopes.canCreate;
    }

    getRequiredUpdateAmenityScopes(): string[] {
        return RoomManagementScopes.canUpdate;
    }

    getRequiredDeleteAmenityScopes(): string[] {
        return RoomManagementScopes.canDelete;
    }

    // Locations

    getRequiredCreateLocationScopes(): string[] {
        return RoomManagementScopes.canCreate;
    }

    getRequiredGetLocationScopes(): string[] {
        return RoomManagementScopes.canRead;
    }

    getRequiredUpdateLocationScopes(): string[] {
        return RoomManagementScopes.canUpdate;
    }

    getRequiredDeleteLocationScopes(): string[] {
        return RoomManagementScopes.canDelete;
    }

    // Products
    getEventTicketsAndProductsMenuScopes(): string[] {
        return menuacl.EventMenu.TicketsAndProducts.Main;
    }

    getRequiredGetProductScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredCreateProductScopes(): string[] {
        return [scope.Financial.Create];
    }

    getRequiredUpdateProductScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Update];
    }

    getRequiredDeleteProductScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Delete];
    }

    getRequiredSearchProductScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    // Global event app
    getAllGlobalEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Create, scope.GlobalEventApp.Read, scope.GlobalEventApp.Update, scope.GlobalEventApp.Delete];
    }

    getRequiredReadGlobalEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Read];
    }

    getRequiredCreateGlobalEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Create];
    }

    getRequiredUpdateGlobalEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Update];
    }

    getRequiredDeleteGlobalEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Delete];
    }

    // Product groups

    getRequiredGetProductGroupScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredCreateProductGroupScopes(): string[] {
        return [scope.Financial.Create];
    }

    getRequiredUpdateProductGroupScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Update];
    }

    getRequiredDeleteProductGroupScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Delete];
    }

    getRequiredSearchProductGroupScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    // Taxes

    getRequiredGetTaxesScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredCreateTaxesScopes(): string[] {
        return [scope.Financial.Create];
    }

    getRequiredUpdateTaxesScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Update];
    }

    getRequiredDeleteTaxesScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Delete];
    }

    getRequiredSearchTaxesScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    // Cancellation termss

    getRequiredGetCancellationTermsScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredCreateCancellationTermsScopes(): string[] {
        return [scope.Financial.Create];
    }

    getRequiredUpdateCancellationTermsScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Update];
    }

    getRequiredDeleteCancellationTermsScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Delete];
    }

    getRequiredSearchCancellationTermsScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    // Orders

    getRequiredOrdersGetScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredOrdersSearchScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Read, scope.Financial.Update, scope.Financial.Delete];
    }

    getRequiredOrderRefundScopes(): string[] {
        return [scope.Financial.Create, scope.Financial.Update];
    }

    getRequiredEventOrderRefundScopes(): string[] {
        return [scope.Event.Create, scope.Event.ManageFinances];
    }

    getEventTicketsAndProductsPaymentSettingsMenuScopes(): string[] {
        return menuacl.EventMenu.TicketsAndProducts.SubMenu.PaymentSettings;
    }

    // Flow builder
    getRequiredUpdateProcessLightScopes(): string[] {
        return [scope.ParticipantProcess.UpdateAndControl];
    }

    getRequiredUpdateGlobalProcessLightScopes(): string[] {
        return [scope.EventProcess.Update];
    }

    // Events series
    getEventsSeriesMenuScopes(): string[] {
        return menuacl.GlobalMenu.Events.SubMenu.EventSeries;
    }

    getSeriesScopesCanUpdateTeams(): string[] {
        return SeriesScopes.canUpdateTeams;
    }

    getSeriesScopesCanRead(): string[] {
        return SeriesScopes.canRead;
    }

    getSeriesScopesCanCreate(): string[] {
        return SeriesScopes.canCreate;
    }

    getSeriesScopesCanDelete(): string[] {
        return SeriesScopes.canDelete;
    }

    getSeriesScopesCanPublish(): string[] {
        return SeriesScopes.canPublish;
    }

    getSeriesScopesCanUpdate(): string[] {
        return SeriesScopes.canUpdate;
    }

    // Generic team
    getGenericTeamInviteScope(): string {
        return scope.GenericTeam.InviteGenericTeam;
    }

    // Events
    getEventsSearchScopes(): string[] {
        return [
            scope.Event.Read, scope.Event.Update, scope.Event.Create, scope.Event.Delete,
            scope.Participant.Create, scope.Participant.Update, scope.Participant.Delete
        ];
    }

    getRequiredEventViewsScopes(): string[] {
        return menuacl.Views.Event;
    }

    getRequiredEventParticipantViewsScopes(): string[] {
        return menuacl.Views.Partcipant;
    }

    getRequiredCRMViewsScopes(): string[] {
        return menuacl.Views.CRM;
    }

    getEventScopesCanCancel(): string[] {
        return EventScopes.canCancel;
    }

    getEventScopesCanUpdateSettings(): string[] {
        return EventScopes.canUpdateSettings;
    }

    getEventCreateScope(): string {
        return scope.Event.Create;
    }

    getEventScopesCanCreate(): string[] {
        return EventScopes.canCreate;
    }

    getEventScopesCanRead(): string[] {
        return EventScopes.canRead;
    }

    getEventScopesCanUpdate(): string[] {
        return EventScopes.canUpdate;
    }

    getEventScopesCanDelete(): string[] {
        return EventScopes.canDelete;
    }

    getEventScopesCanCopy(): string[] {
        return EventScopes.canCopy;
    }

    getEventScopesCanUpdateLocalFields(): string[] {
        return EventScopes.canUpdateLocalFields;
    }

    getRequiredShareEventDashboardScopes(): string[] {
        return [scope.Event.Create];
    }

    // Event event app
    getEventEventAppCanReadScopes(): string[] {
        return EventEventAppScopes.canRead;
    }

    getEventEventAppCanCreateScopes(): string[] {
        return EventEventAppScopes.canCreate;
    }

    getEventEventAppCanUpdateScopes(): string[] {
        return EventEventAppScopes.canUpdate;
    }

    getEventEventAppCanDeleteScopes(): string[] {
        return EventEventAppScopes.canDelete;
    }

    // Event email templates
    getEventEmailMarketingMenuScopes(): string[] {
        return menuacl.EventMenu.EmailMarketing.Main;
    }

    getRequiredCreateEventEmailTemaplateScopes(): string[] {
        return PageScopes.canCreate.Emailtemplate;
    }

    getRequiredSearchEventEmailTemaplateScopes(): string[] {
        return PageScopes.canRead.Emailtemplate;
    }

    getRequiredUpdateEventEmailTemplateScopes(): string[] {
        return PageScopes.canUpdate.Emailtemplate;
    }

    getRequiredUnpublishEventEmailTemplateScopes(): string[] {
        return PageScopes.canPublish.Emailtemplate;
    }

    getRequiredDeleteEventEmailTemplateScopes(): string[] {
        return PageScopes.canDelete.Emailtemplate;
    }

    // Event app
    getRequiredCreateEventAppScopes(): string[] {
        return [scope.GlobalEventApp.Create];
    }

    // Event email campaigns

    // getRequiredCreateEventEmailCampaignScopes(): string[] {
    //     return [scope.EventEmail.CreateCampaign];
    // }

    // getRequiredDeleteEventEmailCampaignScopes(): string[] {
    //     return [scope.EventEmail.CreateCampaign, scope.EventEmail.DeleteCampaign];
    // }

    getEmailCampaignTypeReadScopes(): string[] {
        return EmailCampaignTypeScopes.canRead;
    }

    getEmailCampaignTypeCreateScopes(): string[] {
        return EmailCampaignTypeScopes.canCreate;
    }

    getEmailCampaignTypeUpdateScopes(): string[] {
        return EmailCampaignTypeScopes.canUpdate;
    }

    getEmailCampaignTypeDeleteScopes(): string[] {
        return EmailCampaignTypeScopes.canDelete;
    }

    getCampaignScopesCanCreateEvent(): string[] {
        return CampaignScopes.canCreate.Event;
    }

    getCampaignScopesCanDeleteEvent(): string[] {
        return CampaignScopes.canDelete.Event;
    }

    getCampaignScopesCanReadEvent(): string[] {
        return CampaignScopes.canRead.Event;
    }

    getCampaignScopesCanUpdateEvent(): string[] {
        return CampaignScopes.canUpdate.Event;
    }

    getCampaignScopesCanSendEmailEvent(): string[] {
        return CampaignScopes.sendMail.Event;
    }

    getCampaignScopesCanDeleteContact(): string[] {
        return CampaignScopes.canDelete.Contact;
    }

    getCampaignScopesCanReadContact(): string[] {
        return CampaignScopes.canRead.Contact;
    }

    getCampaignScopesCanCreateContact(): string[] {
        return CampaignScopes.canCreate.Contact;
    }

    getCampaignScopesCanSendEmailContact(): string[] {
        return CampaignScopes.sendMail.Contact;
    }

    // Contacts email campaigns
    // getRequiredCreateContactsEmailCampaginScopes(): string[] {
    //     return [scope.CRMEmail.CreateCampaign];
    // }

    getEmailMarketingEmailCampaignsMenuScopes(): string[] {
        return menuacl.GlobalMenu.EmailMarketing.SubMenu.EmailCampaigns;
    }


    // Contacts email templates
    getRequiredCreateContactsEmailTemplateScopes(): string[] {
        return PageScopes.canCreate.ContactEmailtemplate;
    }

    getEmailMarketingMenuScopes(): string[] {
        return menuacl.GlobalMenu.EmailMarketing.Main;
    }

    getRequiredSearchContactsEmailTemplateScopes(): string[] {
        return PageScopes.canRead.ContactEmailtemplate;
    }

    getRequiredUpdateContactsEmailTemplateScopes(): string[] {
        return PageScopes.canUpdate.ContactEmailtemplate;
    }

    getRequiredUnpublishContactsEmailTemplateScopes(): string[] {
        return PageScopes.canPublish.ContactEmailtemplate;
    }

    getRequiredDeleteContactsEmailTemplateScopes(): string[] {
        return PageScopes.canDelete.ContactEmailtemplate;
    }

    getEventDocumentsMenuScopes(): string[] {
        return menuacl.EventMenu.Documents.Main;
    }

    getCRMEmailCreateAssetScopes(): string[] {
        return [scope.CRMEmail.CreateAsset];
    }

    getCRMEmailDeleteAssetScopes(): string[] {
        return [scope.CRMEmail.DeleteAsset];
    }

    // Event Activities

    getEventActivitiesMenuScopes(): string[] {
        return menuacl.EventMenu.Activities.Main;
    }

    getActivityScopesCanRead(): string[] {
        return ActivityScopes.canRead;
    }

    getActivityScopesCanCreate(): string[] {
        return ActivityScopes.canCreate;
    }

    getActivityScopesCanUpdate(): string[] {
        return ActivityScopes.canUpdate;
    }

    getActivityScopesCanDelete(): string[] {
        return ActivityScopes.canDelete;
    }

    getActivityScopesCanExport(): string[] {
        return ActivityScopes.canExport;
    }

    getRequiredEventActivityGetSpeakersScopes(): string[] {
        return ActivityScopes.canRead;
    }

    getRequiredEventActivityCreateSpeakersScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredEventActivityRemoveSpeakersScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredEventActivityGetModeratorsScopes(): string[] {
        return ActivityScopes.canRead;
    }

    getRequiredEventActivityCreateModeratorsScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredEventActivityUpdateParticipantsStatusScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredEventActivityRemoveModeratorsScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredAddParticipantsToActivitiesScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    getRequiredRemoveParticipantsFromActivitiesScopes(): string[] {
        return ActivityScopes.canUpdate;
    }

    // Event website pages dependencies
    getRequiredReadEventWebsiteDependenciesScopes(): string[] {
        return DependencyScopes.canRead;
    }

    getRequiredUpdateEventWebsiteDependenciesScopes(): string[] {
        return DependencyScopes.canUpdate;
    }

    getRequiredCreateEventWebsiteDependenciesScopes(): string[] {
        return DependencyScopes.canCreate;
    }

    getRequiredDeleteEventWebsiteDependenciesScopes(): string[] {
        return DependencyScopes.canDelete;
    }

    // Event website pages
    getRequiredEditEventWebsiteMenuScopes(): string[] {
        return PageScopes.canUpdate.Page;
    }

    getEventWebsiteMenuScopes(): string[] {
        return menuacl.EventMenu.Website.Main;
    }

    getPagePreviewScopes(): string[] {
        return PageScopes.canRead.Page;
    }

    getRequiredDeleteEventPageScopes(): string[] {
        // TODO: Should we assume that create scope allows deletion too like in other entities ?
        return PageScopes.canDelete.Page;
    }

    getRequiredCreateEventPageScopes(): string[] {
        return PageScopes.canCreate.Page;
    }

    getRequiredReadEventPageScopes(): string[] {
        return PageScopes.canRead.Page;
    }

    getRequiredUpdateEventPageScopes(): string[] {
        return PageScopes.canUpdate.Page;
    }

    getRequiredPublishEventPageScopes(): string[] {
        return PageScopes.canPublish.Page;
    }

    // Event teams
    getRequiredAddAndUnassignEventTeamsScopes(): string[] {
        return [scope.Event.Create];
    }

    // Event global fields

    getRequiredSearchGlobalEventFieldScopes(): string[] {
        return [scope.EventField.Create, scope.EventField.Update];
    }

    getEventFieldScopesCanCreateGlobals(): string[] {
        return EventFieldScopes.canCreateGlobals;
    }

    getEventFieldScopesCanUpdateGlobals(): string[] {
        return EventFieldScopes.canUpdateGlobals;
    }

    getEventFieldScopesCanDeleteGlobals(): string[] {
        return EventFieldScopes.canDeleteGlobals;
    }

    // Event local fields
    getEventFieldsManagerMenuScopes(): string[] {
        return menuacl.EventMenu.FieldsManager.Main;
    }

    getAllLocalEventFieldScopes(): string[] {
        return [scope.EventField.CreateLocal, scope.EventField.DeleteLocal, scope.EventField.UpdateLocal];
    }

    getRequiredSearchLocalEventFieldScopes(): string[] {
        return EventScopes.canList;
    }

    getEventScopesCanCreateLocalFields(): string[] {
        return EventScopes.canCreateLocalFields;
    }

    getEventScopesCanDeleteLocalFields(): string[] {
        return EventScopes.canDeleteLocalFields;
    }

    // Event participant fields
    getAllEventParticipantFieldsScopes(): string[] {
        return [scope.ParticipantField.Create, scope.ParticipantField.Delete, scope.ParticipantField.Read, scope.ParticipantField.Update];
    }

    getRequiredSearchEventParticipantFieldScopes(): string[] {
        return EventScopes.canList;
    }

    getEventScopesCanCreateParticipantFields(): string[] {
        return EventScopes.canCreateParticipantFields;
    }

    getEventScopesCanUpdateParticipantFields(): string[] {
        return EventScopes.canUpdateParticipantFields;
    }

    getEventScopesCanDeleteParticipantFields(): string[] {
        return EventScopes.canDeleteParticipantFields;
    }

    // Event participant update stage
    getRequiredEventParticipantUpdateStageScopes(): string[] {
        return [scope.Event.Create, scope.Event.Update];
    }

    // Event participants
    getParticipantScopesCanRead(): string[] {
        return ParticipantScopes.canRead;
    }

    getParticipantScopesCanUpdateTeams(): string[] {
        return ParticipantScopes.canUpdateTeams;
    }

    getParticipantScopesCanCreate(): string[] {
        return ParticipantScopes.canCreate;
    }

    getParticipantScopesCanDelete(): string[] {
        return ParticipantScopes.canDelete;
    }

    getParticipantScopesCanUpdate(): string[] {
        return ParticipantScopes.canUpdate;
    }

    getParticipantScopesCanImpersonate(): string[] {
        return ParticipantScopes.canImpersonate;
    }

    getParticipantScopesCanExport(): string[] {
        return ParticipantScopes.canExport;
    }

    getRequiredEmailEventParticipantsScopes(): string[] {
        return CampaignScopes.sendMail.Event;
    }

    // Contacts
    getDocumentsMenuScopes(): string[] {
        return menuacl.GlobalMenu.Documents.Main;
    }

    getContactScopesCanRead(): string[] {
        return ContactScopes.canRead;
    }

    getContactScopesCanUpdate(): string[] {
        return ContactScopes.canUpdate;
    }

    getContactScopesCanCreate(): string[] {
        return ContactScopes.canCreate;
    }

    getContactScopesCanDelete(): string[] {
        return ContactScopes.canDelete;
    }

    getRequiredExportContactScopes(): string[] {
        return [scope.CRM.Create, scope.CRM.Delete];
    }

    getRequiredAddContactsToEventScopes(): string[] {
        return [scope.Event.Create, scope.Participant.Create];
    }

    // Contact fields
    getRequiredSearchContactFieldsScopes(): string[] {
        return [
            scope.CRM.Read, scope.CRM.Create, scope.CRM.Update, scope.CRM.Delete,
            scope.ContactField.Create, scope.ContactField.Update, scope.ContactField.Read
        ];
    }

    getRequiredCreateContactFieldsScopes(): string[] {
        return [scope.ContactField.Create];
    }

    getRequiredEditContactFieldsScopes(): string[] {
        return [scope.ContactField.Create, scope.ContactField.Update];
    }

    // Organizations
    getOrganizationsMenuScopes(): string[] {
        return menuacl.GlobalMenu.Organization.Main;
    }

    getOrganizationScopesCanRead(): string[] {
        return OrganizationScopes.canRead;
    }

    getOrganizationScopesCanUpdate(): string[] {
        return OrganizationScopes.canUpdate;
    }

    getOrganizationScopesCanCreate(): string[] {
        return OrganizationScopes.canCreate;
    }

    getOrganizationScopesCanDelete(): string[] {
        return OrganizationScopes.canDelete;
    }

    // Organization fields
    getRequiredSearchOrganizationFieldsScopes(): string[] {
        return [
            scope.CRM.Read, scope.CRM.Create, scope.CRM.Update, scope.CRM.Delete,
            scope.OrganizationField.Create, scope.OrganizationField.Update, scope.OrganizationField.Read
        ];
    }

    getRequiredCreateOrganizationFieldsScopes(): string[] {
        return [scope.OrganizationField.Create];
    }

    getRequiredEditOrganizationFieldsScopes(): string[] {
        return [scope.OrganizationField.Create, scope.OrganizationField.Update];
    }

    // Themes
    getThemesMenuScopes(): string[] {
        return menuacl.GlobalMenu.Theme.Main;
    }

    getRequiredSearchEmailThemesScopes(): string[] {
        return ThemeScopes.canRead.Email;
    }

    getRequiredUpdateEmailThemesScopes(): string[] {
        return ThemeScopes.canUpdate.Email;
    }

    getRequiredCreateEmailThemesScopes(): string[] {
        return ThemeScopes.canCreate.Email;
    }

    getRequiredDeleteEmailThemesScopes(): string[] {
        return ThemeScopes.canDelete.Email;
    }

    getRequiredSearchWebsiteThemesScopes(): string[] {
        return ThemeScopes.canRead.Website;
    }

    getRequiredUpdateWebsiteThemesScopes(): string[] {
        return ThemeScopes.canUpdate.Website;
    }

    getRequiredCreateWebsiteThemesScopes(): string[] {
        return ThemeScopes.canCreate.Website;
    }

    getRequiredDeleteWebsiteThemesScopes(): string[] {
        return ThemeScopes.canDelete.Website;
    }

    getRequiredSearchEngagementAppThemesScopes(): string[] {
        return ThemeScopes.canRead.EngagementApp;
    }

    getRequiredUpdateEngagementAppThemesScopes(): string[] {
        return ThemeScopes.canUpdate.EngagementApp;
    }

    getRequiredCreateEngagementAppThemesScopes(): string[] {
        return ThemeScopes.canCreate.EngagementApp;
    }

    getRequiredDeleteEngagementAppThemesScopes(): string[] {
        return ThemeScopes.canDelete.EngagementApp;
    }

    // Stages

    getEventWorkflowStagesMenuScopes(): string[] {
        return menuacl.EventMenu.Workflows.SubMenu.Stages;
    }

    getStageScopesCanRead(): string[] {
        return StageScopes.canRead;
    }

    getStageScopesCanUpdate(): string[] {
        return StageScopes.canUpdate;
    }

    getStageScopesCanCreate(): string[] {
        return StageScopes.canCreate;
    }

    getStageScopesCanDelete(): string[] {
        return StageScopes.canDelete;
    }


    // Processes
    getEventWorkflowsMenuScopes(): string[] {
        return menuacl.EventMenu.Workflows.Main;
    }

    getEventWorkflowsAutomationsMenuScopes(): string[] {
        return menuacl.EventMenu.Workflows.SubMenu.WorkflowAutomations;
    }

    getEventWorkflowProcessesMenuScopes(): string[] {
        return menuacl.EventMenu.Workflows.SubMenu.Processes;
    }

    getAllGlobalProcessScopes(): string[] {
        return menuacl.GlobalMenu.Integrations.GlobalProcesses;
    }

    getAllGlobalEmailTemplateScopes(): string[] {
        return menuacl.GlobalMenu.Integrations.EmailTemplates;
    }

    getProcessScopesCanReadEvent(): string[] {
        return ProcessScopes.canRead.Event;
    }

    getProcessScopesCanCreateEvent(): string[] {
        return ProcessScopes.canCreate.Event;
    }

    getProcessScopesCanUpdateEvent(): string[] {
        return ProcessScopes.canUpdate.Event;
    }

    getProcessScopesCanDeleteEvent(): string[] {
        return ProcessScopes.canDelete.Event;
    }

    getProcessScopesCanPauseEvent(): string[] {
        return ProcessScopes.canPause.Event;
    }

    getProcessScopesCanInterruptEvent(): string[] {
        return ProcessScopes.canInterrupt.Event;
    }

    getProcessScopesCanReadParticipant(): string[] {
        return ProcessScopes.canRead.Participant;
    }

    getProcessScopesCanUpdateParticipant(): string[] {
        return ProcessScopes.canUpdate.Participant;
    }

    getProcessScopesCanCreateParticipant(): string[] {
        return ProcessScopes.canCreate.Participant;
    }

    getProcessScopesCanDeleteParticipant(): string[] {
        return ProcessScopes.canDelete.Participant;
    }

    getProcessScopesCanPauseParticipant(): string[] {
        return ProcessScopes.canPause.Participant;
    }

    getProcessScopesCanInterruptParticipant(): string[] {
        return ProcessScopes.canInterrupt.Participant;
    }


    // Global processes

    getGlobalParticipantProcessReadScopes(): string[] {
        return GlobalProcessScopes.canRead.Participant;
    }

    getGlobalParticipantProcessCreateScopes(): string[] {
        return GlobalProcessScopes.canCreate.Participant;
    }

    getGlobalEventProcessScopesCanUpdate(): string[] {
        return GlobalProcessScopes.canUpdate.Event;
    }

    getGlobalEventProcessScopesCanDelete(): string[] {
        return GlobalProcessScopes.canDelete.Event;
    }

    getProcessScopesCanUpdateContact(): string[] {
        return ProcessScopes.canUpdate.Contact;
    }

    getProcessScopesCanPauseContact(): string[] {
        return ProcessScopes.canPause.Contact;
    }

    getProcessScopesCanInterruptContact(): string[] {
        return ProcessScopes.canInterrupt.Contact;
    }

    getProcessScopesCanDeleteContact(): string[] {
        return ProcessScopes.canDelete.Contact;
    }

    getProcessScopesCanUpdateOrganization(): string[] {
        return ProcessScopes.canUpdate.Organization;
    }

    getProcessScopesCanPauseOrganization(): string[] {
        return ProcessScopes.canPause.Organization;
    }

    getProcessScopesCanInterruptOrganization(): string[] {
        return ProcessScopes.canInterrupt.Organization;
    }

    getProcessScopesCanDeleteOrganization(): string[] {
        return ProcessScopes.canDelete.Organization;
    }


    // Integrations

    getIntegrationScopesCanRead(): string[] {
        return IntegrationScopes.canRead;
    }

    getIntegrationScopesCanCreate(): string[] {
        return IntegrationScopes.canCreate;
    }

    getIntegrationScopesCanUpdate(): string[] {
        return IntegrationScopes.canUpdate;
    }

    getIntegrationScopesCanDelete(): string[] {
        return IntegrationScopes.canDelete;
    }

    // Webhooks
    getWebhookScopesCanRead(): string[] {
        return WebhookScopes.canRead;
    }

    // Virtual meetings
    getVirtualMeetingCanRead(): string[] {
        return VirtualMeetingScopes.canRead;
    }

    getVirtualMeetingCanUpdate(): string[] {
        return VirtualMeetingScopes.canUpdate;
    }

    // Profile pages
    getProfilePageScopesCanRead(): string[] {
        return ProfilePageScopes.canRead;
    }

    getProfilePageScopesCanCreate(): string[] {
        return ProfilePageScopes.canCreate;
    }

    getProfilePageScopesCanDelete(): string[] {
        return ProfilePageScopes.canDelete;
    }

    getProfilePageScopesCanUpdate(): string[] {
        return ProfilePageScopes.canUpdate;
    }

    // Project settings
    getProjectMenuScopes(): string[] {
        return menuacl.GlobalMenu.Project.Main;
    }

    getProjectCanDeleteScopes(): string[] {
        return ProjectScopes.canDelete;
    }

    getProjectCanUpdateScopes(): string[] {
        return ProjectScopes.canUpdate;
    }

    getProjectCanCreateScopes(): string[] {
        return ProjectScopes.canCreate;
    }

    getProjectCanReadScopes(): string[] {
        return ProjectScopes.canRead;
    }

    // Project tasks
    getProjectTaskCanCreateScopes(): string[] {
        return TaskScopes.canCreate;
    }

    getProjectTaskCanReadScopes(): string[] {
        return TaskScopes.canRead;
    }

    getProjectTaskCanUpdateScopes(): string[] {
        return TaskScopes.canUpdate;
    }

    getProjectTaskCanDeleteScopes(): string[] {
        return TaskScopes.canDelete;
    }
}
